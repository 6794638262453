@font-face {
  font-family: 'Open Sans';
  src: url('./app/assets/fonts/OpenSans-VariableFont_wdth,wght.ttf') format("truetype-variations");
}

@font-face {
  font-family: 'Open Sans';
  src: url('./app/assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf') format("truetype-variations");
  font-style: italic;
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
  min-width: 1024px;
  font-size: 14px;
}

p {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
